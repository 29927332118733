// Generic imports
import React, { Component } from "react"
import styled from "styled-components"

// Other components imports
import { Link } from "gatsby"

export const Styles = styled.button`
  position: relative;
  color: white;
  background-color: #efa51d;
  border-width: 0px;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0.1rem;
  box-shadow: 0 0 #0000;
  border: 1px solid #efa51d;

  &:hover {
    filter: brightness(0.95);
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);
  }

  &.small {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  }

  &.secondary {
    color: black;
    background-color: white;
    margin: 0.1rem;
  }

  &.secondary:hover {
    color: white;
    background-color: #efa51d;
  }

  &:disabled {
    opacity: 0.6;
    filter: none;
    box-shadow: none;
  }

  &:disabled:hover {
    background-color: #efa51d;
    color: white;
  }
`

class Button extends Component {
  render() {
    const props = this.props
    if (props.to) {
      return (
        <Link to={props.to}>
          <Styles {...props}>{props.children}</Styles>
        </Link>
      )
    }
    if (props.newPage && props.href) {
      return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          <Styles {...props}>{props.children}</Styles>
        </a>
      )
    }
    if (props.href) {
      return (
        <a href={props.href}>
          <Styles {...props}>{props.children}</Styles>
        </a>
      )
    }
    return <Styles {...props}>{props.children}</Styles>
  }
}

export default Button
